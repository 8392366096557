import DomElementAnimation from '../DomElementAnimation'
import { gsap } from 'gsap'

/**
 * A custom element class for accessing window events and associated callbacks for animations
 * @extends DomElementAnimation
 * @property {Function} animate the animation this class will run
 * @property {Function} setDefault set defaults for animation or element
 * @property {Function} onViewportExit a callback function for intersection observer exit
 */
class Fade extends DomElementAnimation {
	/**
	   * Runs the animation
	   * @param {function} done animation complete callback, passed into run()
	   */

	animate({ done }) {
		this.element.resetOnExit = false

		gsap.fromTo(this.element, {
			opacity: 0,
			rotateX: '10deg'
		}, {
			opacity: 1,
			duration: 2,
			rotateX: '0deg',
			delay: 0.3,
			ease: 'power4.out',
			onComplete: done
		})
	}

	/**
																													   * Will fire when element binds to the DOM, use this to
																													   * set up default states if needed
																													   */
	setDefault() {
		/** --- Start hidden --- */
		gsap.set(this.element, {
			opacity: 0
		})
	}

	/**
																													   * Intersection Observer callback, only needs to fire this.run()
																													   * this.run() can contain a callback function for done()
																													   */
	onViewportEnter() {
		this.run((done) => {
			// 
		})
	}
}

export default Fade
