
const onScroll = () => {
    /** Grab scroll */
    self.Decimal = self.Decimal || {}
    self.Decimal.scroll = self.Decimal.scroll || {}
    self.Decimal.scroll.current = window.scrollY
    self.Decimal.scroll.currentProxy.s = self.Decimal.scroll.current

    /** Set up first load */
    if (self.Decimal.scroll.last === null) {
        self.Decimal.scroll.last = self.Decimal.scroll.current

        return
    }

    /** Bounce if equal */
    if (self.Decimal.scroll.current === self.Decimal.scroll.last) {
        self.Decimal.scroll.direction.dir = 'idle'

        return
    }

    /** Set direction */
    if (self.Decimal.scroll.current > self.Decimal.scroll.last && self.Decimal.scroll.direction.dir != 'down') self.Decimal.scroll.direction.dir = 'down'
    if (self.Decimal.scroll.current < self.Decimal.scroll.last && self.Decimal.scroll.direction.dir != 'up') self.Decimal.scroll.direction.dir = 'up'

    self.Decimal.scroll.last = self.Decimal.scroll.current
}

window.addEventListener('scroll', onScroll.bind(this))
