import * as Anim from './domElementAnimations'

export default class AnimationInitializer {
	/**
		 * @arg {object} opts — User options
		 * @arg {object} opts.selector — Data attribute that contains the animation Class name
		 * @arg {object} opts.threshold — Base threshold for our IntersectionObserver
		 * @constructor
		 */
	constructor(opts = {}) {
		/** Defaults */
		this.selector = opts.selector || '[data-animation]'
		this.threshold = opts.threshold || 0

		this.elements = []
		this.types = Anim
	}

	/**
		 * Attached our animation classes
		 */
	init() {
		this.selected = document.querySelectorAll(this.selector)

		Array.prototype.slice.call(this.selected).forEach((el, key) => {
			const type = (el.dataset.animation && el.dataset.animation != '') ? el.dataset.animation : null

			if (!type || !this.types[type]) return

			this.elements[key] = {}
			this.elements[key].el = el
			this.elements[key].anim = new this.types[type](el)

			/** pass the reference so we can grab this element out of the window object */
			el.dataset.idx = key
		})
	}
}
