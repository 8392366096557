
import gsap from 'gsap'
import { useIntersectionObserver } from '../helpers'

/**
 * @file Default transition that handle DOM animations.
 */
export default class DomElementAnimation {
	/**
	 * @arg {object} element — Animation element
	 * @constructor
	 */

	constructor(element, options = {}) {
		this.element = element
		this.element.resetOnExit = false
		this.type = (this.element.dataset.animation) ? this.element.dataset.animation : 'default'
		this.trigger = (this.element.dataset.trigger) ? this.element.dataset.trigger : 'observe'

		if (this.setDefault) this.setDefault()

		const span = document.createElement('span')
		span.style.position = 'absolute'
		this.spanEl = this.element.appendChild(span)

		// gsap.ticker.add(this.raf.bind(this))

		useIntersectionObserver(this, this.element)
	}

	raf() {

		const fps = gsap.ticker.frame / gsap.ticker.time
		// 

		this.spanEl.textContent = fps
	}

	run(callback = null) {
		this.promise = new Promise(resolve => {

			this.element.style.visibility = 'visible'
			this.animate && this.animate({ done: resolve })
		})

		if (callback) this.promise.then(callback)
	}
}
