import DomElementAnimation from '../DomElementAnimation'
import { gsap } from 'gsap'
import { basic } from '../_eases'

/**
 * A custom element class for accessing window events and associated callbacks for animations
 * @extends DomElementAnimation
 * @property {Function} animate the animation this class will run
 * @property {Function} setDefault set defaults for animation or element
 * @property {Function} onViewportExit a callback function for intersection observer exit
 */
class StaggerFade extends DomElementAnimation {
  /**
   * Runs the animation
   * @param {function} done animation complete callback, passed into run()
   */
  animate({ done }) {
    // 
    this.tl.eventCallback('onComplete', done)
    this.tl.play(0)
  }

  /**
   * Will fire when element binds to the DOM, use this to
   * set up default states if needed
   */
  setDefault() {
    // this.element.resetOnExit = true
    this.animEls = this.element.querySelectorAll('.js-stagger')

    const common = {
      stagger: 0.3
    }

    /** --- Start hidden --- */

    gsap.set(this.animEls, {
      autoAlpha: 0
    })

    this.tl = gsap.timeline()

    this.tl.to(
      this.animEls,
      {
        autoAlpha: 1,
        duration: 0.8,
        ease: 'circ.in',
        ...common
      }
    )
  }

  // onViewportExit() {
  // 	gsap.set(this.animEls, {
  // 		opacity: 0,
  // 		y: -100
  // 	})
  // }

  /**
   * Intersection Observer callback, only needs to fire this.run()
   * this.run() can contain a callback function for done()
   */
  onViewportEnter() {
    this.run(() => {
      // 
    })
  }
}

export default StaggerFade
