const URL = 'https://a.klaviyo.com/ajax/subscriptions/subscribe'

const getListKey = (listId) => `klaviyo_signed_${listId}`

export const getStoredList = (listId) =>
    window.localStorage.getItem(getListKey(listId))

const storeSignupEvent = (listId) => {
    const exists = getStoredList(listId)
    if (!exists) {
        window.localStorage.setItem(getListKey(listId), 'true')
    }
}

export function subscribe(email, firstName, lastName, tag, listId, params = {}) {
    if (!listId) {
        return console.error('Please provide a list id')
    }
    const config = Object.assign({}, params, {
        g: listId,
        $email: email,
        $first_name: firstName,
        $last_name: lastName,
        $fields: 'resonates_with,$email,services,$timezone_offset',
        $source: 'Landing Page',
        $timezone_offset: new Date().getTimezoneOffset() / -60,
        services: '{"shopify":{"source":"form"}}',
        $tag: tag,
        $fields: 'resonates_with,$email,services,$timezone_offset,$tag'

    })

    const body = Object.keys(config).reduce((str, key) => {
        str.append(key, config[key])
        return str
    }, new URLSearchParams())

    return fetch(URL, {
        method: 'POST',
        headers: {
            'Access-Control-Allow-Headers': '*',
            'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
        },
        body
    }).then(async (res) => {
        const jsonRes = await res.json()
        if (!jsonRes.errors.length) storeSignupEvent(listId)
        return jsonRes
    })
}

function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

export default {
    subscribe,
    validateEmail
}

// window.subscribe = subscribe
